import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';

import Navi from './components/Navi';
import Flag from './components/Flags';
import Somos from './components/Somos';
import Steps from './components/Steps';
import Review from './components/Review';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Hero from './components/Hero';
import QuoteForm from './components/FormQuote';
import Thanks from './components/Thanks';
import  ReactGA  from 'react-ga4';

function App() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.bitrix24.com/b2514537/crm/site_button/loader_16_p94bzx.js?' + (Date.now() / 60000 | 0);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    ReactGA.initialize('AW-11239929039');
  }, [])

  return (
    <Router>
      <div className="App">
        <Navi />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              {
                isSubmitted
                  ? <Thanks newQuote={() => setIsSubmitted(false)} />
                  : <QuoteForm setIsSubmitted={() => setIsSubmitted(true)} />
              }
              <Flag />
              <Somos />
              <Steps />
              <Review />
              <Contact />
            </>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
