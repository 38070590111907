import { Row, Col, Card } from 'react-bootstrap';

const cardContentArray = [
  {
    id: 1,
    title: 'Carla Martínez',
    paragraph: 'Estoy muy agradecida con la empresa por su excelente servicio. Desde el primer contacto hasta la entrega, demostraron profesionalismo y atención al cliente.'
  },
  {
    id: 2,
    title: 'Juan Ramírez',
    paragraph: 'Fue excepcional. Su equipo fue eficiente y atento, asegurándose de todo el proceso fuera sin problemas. Recomiendo sus servicios a cualquiera que busque transportar su vehículo.'
  },
  {
    id: 3,
    title: 'Julian Londoño',
    paragraph: 'Mi experiencia de envío fue satisfactoria. Su equipo fue eficiente y atento, asegurándose de que todo el proceso fuera sin problemas. Los recomendaré a amigos y familiares.'
  },
]

const titleH1Style = { fontSize: '60px', lineHeight: '72px', fontWeight: '500' }
const Review = () => {

  return (
    <section className='d-flex align-items-center w-100 bg-green-light-cu p-lg-5 py-5 flex-column gap-4  ' id="review">
      <div className='container text-center mx-lg-5 px-lg-5 flex-column'>
        <h1 style={titleH1Style} >Experiencias de nuestros clientes</h1>
      </div>
      <div>
        <Row id='reviewRow' xs={1} md={3} className='container align-content-center mx-lg-auto mb-lg-5 px-lg-5 d-flex gap-3  gap-lg-2 justify-content-between' >
          {cardContentArray.map((card) => (
            <Col id='reviewCol' key={card.id} style={{ width: '32%', height: '70%' }} >
              <Card id='reviewCard' style={{ width: '100%', height: '100%' }}>
                <Card.Body className='none-border rounded-3 bg-green-light-cu-2 position-relative p-4'>
                  <Card.Title className='card-title-black'>{card.title}</Card.Title>
                  <Card.Text className='card-text-sm pb-3 pt-2 p-lg-auto'>
                    {card.paragraph}
                  </Card.Text>
                  <div className='d-flex justify-content-end position-absolute bottom-0 end-0 mb-3 me-3 mb-lg-4 me-lg-4'>
                    {StarRating()}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default Review;

const StarRating = () => {
  const stars = Array(5).fill(0); // Crea un array de 5 elementos

  return (
    <div>
      {stars.map((_, index) => (
        <svg
          key={index}
          width="22"
          height="21"
          className="mx-1"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.9844 9.22318L16.7562 12.9132L18.0228 18.4069C18.0898 18.6941 18.0707 18.9947 17.9678 19.2711C17.8649 19.5475 17.6829 19.7874 17.4444 19.9609C17.2059 20.1344 16.9215 20.2337 16.6269 20.2464C16.3323 20.2592 16.0404 20.1848 15.7878 20.0326L10.9962 17.1263L6.21499 20.0326C5.9624 20.1848 5.67054 20.2592 5.37591 20.2464C5.08127 20.2337 4.79693 20.1344 4.55843 19.9609C4.31993 19.7874 4.13786 19.5475 4.03498 19.2711C3.9321 18.9947 3.91297 18.6941 3.97999 18.4069L5.24468 12.9188L1.01562 9.22318C0.791939 9.03027 0.630195 8.77561 0.550671 8.49113C0.471146 8.20666 0.477381 7.90504 0.568592 7.6241C0.659804 7.34315 0.831932 7.09539 1.06339 6.91188C1.29485 6.72838 1.57534 6.61729 1.86968 6.59256L7.44406 6.10974L9.61999 0.919745C9.73362 0.647416 9.92529 0.414795 10.1709 0.251174C10.4164 0.0875517 10.7049 0.000244141 11 0.000244141C11.2951 0.000244141 11.5836 0.0875517 11.8291 0.251174C12.0747 0.414795 12.2664 0.647416 12.38 0.919745L14.5625 6.10974L20.135 6.59256C20.4293 6.61729 20.7098 6.72838 20.9413 6.91188C21.1727 7.09539 21.3449 7.34315 21.4361 7.6241C21.5273 7.90504 21.5335 8.20666 21.454 8.49113C21.3745 8.77561 21.2127 9.03027 20.9891 9.22318H20.9844Z"
            fill="#0082A6"
          />
        </svg>
      ))}
    </div>
  );
}