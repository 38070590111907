import { Button } from "react-bootstrap";
import { useCustomNavigate } from '../utils/customNavigation';
import ReactGA from 'react-ga4';

const Contact = () => {
  ReactGA.initialize('AW-11239929039');

  const handleClickPhone = () => {
    window.gtag('config', 'AW-11239929039/IskqCLXgwp4ZEM_pzu8p', { 'phone_conversion_number': '+1 870 600 1993' });
   };
  const customNavigate = useCustomNavigate();
  return (
    <section id='contact' style={{ height: "50vh" }} className="d-flex justify-lg-content-center align-md-items-center contact-background blu-background-filter px-lg-5 py-5">
      <div className="container d-flex flex-column px-lg-5 gap-4 flex-lg-row justify-lg-content-between align-md-align-center">
        <div className="d-flex align-items-center w-50">
          <h1 className="hero-primary-text" style={{ fontSize: "85px", lineHeight: "67px" }}>Reserva Ahora <br /> con Nosotros</h1>
        </div>
        <div className="d-flex align-items-end w-50 flex-column justify-content-center ">
          <div className="d-flex justify-content-between mb-3 gap-4 mx-lg-3">
            <img src="/images/contact/mastercard-logo.svg" alt="mastercard logo" />
            <img src="/images/contact/paypal-logo.svg" alt="paypal logo" />
            <img src="/images/contact/zelle-logo.svg" alt="zelle logo" />
            <img src="/images/contact/visa-logo.svg" alt="visa logo" />
          </div>
          <div className="d-flex flex-column flex-lg-row gap-4 justify-lg-content-center gap-lg-3">
            <Button className="px-5" variant="primary" onClick={() => customNavigate('#cotizar')}>Cotizar</Button>
            <Button className="px-4 d-flex gap-2  justify-content-center" variant="outline-light" href="tel:18704456742" onClick={handleClickPhone}>
              <img src="/images/contact/phone-icon-light.svg" alt="phone icon"/>1-870-445-6742</Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
