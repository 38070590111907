import { Card, Col, Row } from 'react-bootstrap';



const cardContentArray = [
  {
    id: 1,
    title: 'Libre competencia en el mercado',
    paragraph: 'Obtén hasta 5 cotizaciones para el transporte de vehículos. Compara y elige la opción más conveniente para ti.'
  }, {
    id: 2,
    title: 'Tarifas competitivas',
    paragraph: 'Nos asociamos con transportistas confiables que ofrecen las tarifas más competitivas del mercado.'
  },
  {
    id: 3,
    title: 'Seguridad en primer lugar',
    paragraph: 'Trabajamos solo con transportistas que ofrecen seguro completo en caso de accidente.'
  },
  {
    id: 4,
    title: 'Sin costo adicional',
    paragraph: 'Nuestro servicio de conexión entre clientes y transportistas es totalmente gratuito para ti como cliente.'
  },
  {
    id: 5,
    title: 'Base de datos de calidad',
    paragraph: 'Recomendamos solo a los principales transportistas del mercado, gracias a nuestra exhaustiva base de datos.'
  },
  {
    id: 6,
    title: 'Atención en español',
    paragraph: 'Ofrecemos asesoramiento completo en español para todas tus necesidades de transporte de vehículos.'
  }
];

const titleH1Style = { fontSize: '50px', lineHeight: '72px', fontWeight: '500' }
const textPStyle = { fontSize: '30px', lineHeight: '30px', fontWeight: '400' }

const Somos = () => {
  return (
    <section className='d-flex  align-items-lg-center w-100 bg-green-light-cu p-lg-5 py-5 flex-column ' id="somos">
      <div className='container text-lg-center mb-lg-4 mt-lg-3 mx-lg-auto px-lg-5 flex-column'>
        <h1 style={titleH1Style}>¿Por qué somos tu mejor opción?</h1>
        <p style={textPStyle} className='text-gray'>Explora nuestra sección de beneficios para descubrir por qué somos la elección preferida en el transporte de vehículos en Centroamérica.</p>
      </div>
      <div className='mx-lg-5 container'>
        <Row id='somosRow' xs={1}  md={3} className=' justify-content-lg-between mx-lg-auto mb-lg-5 px-lg-5 d-flex gap-4' style={{ height: '500px' }} >
          {cardContentArray.map((card) => (
            <Col id='somosCol' key={card.id} style={{ width: '30%', height: '45%' }} >
              <Card id='somosCard' style={{ width: '100%', height: '100%' }}>
                <Card.Body className={` none-border rounded-3  ${card.id % 2 === 0 ? 'bg-green-light-cu-2' : 'bg-white-light-cu'}`}>
                  <Card.Title>{card.title}</Card.Title>
                  <Card.Text>
                    {card.paragraph}
                  </Card.Text>

                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default Somos;
