import React from 'react';
import { Button, Form } from 'react-bootstrap';


const titleH1Style = { fontSize: '30px', lineHeight: '45px', fontWeight: '600' };
const titleH3Style = { fontSize: '18px', lineHeight: '27px', fontWeight: '500' }
const textStyle = { fontSize: '14px', lineHeight: '22.4px', fontWeight: '400' }
const smallTextStyle = { fontSize: '12px', lineHeight: '19.2px', fontWeight: '300' }
const Footer = () => {
  return (
    <section id='footer' className="bg-custom-green d-flex flex-column justify-lg-content-center align-items-center p-lg-5 py-lg-5">
      <div className='container px-lg-5 mb-lg-5 d-flex flex-column flex-lg-row gap-lg-5 justify-content-between pt-5'>
        <div className='d-lg-none m-0 p-0'>
          <img src="/carrosacentroamerica.svg"
            width="75"
            height="65"
            alt="Logo TCP" />
        </div>
        <div className='d-flex flex-column gap-3 mb-5'>
          <h1 style={titleH1Style}>Carros a Centro America</h1>
          <div >
            <h3 style={titleH3Style}>Contactanos:</h3>
            <p style={textStyle}>1-870-445-6742 <br />info@carrosacentroamerica.com</p>
            <a href="https://www.instagram.com/carrosacentroamerica/" rel="noopener noreferrer" target="_blank">
              <img src="/images/Instagram-light-icon.svg" alt="Instagram logo" />
            </a>
          </div>
        </div>
        <div className='d-flex flex-column pt-2  gap-2'>
          <h3 style={titleH3Style}>Suscribete</h3>
          <p style={textStyle}>Mantente actualizado con nuestras últimas noticias y ofertas.</p>
          <Form className='d-flex flex-column gap-3'>
            <Form.Group className='d-flex flex-column flex-lg-row gap-3' aria-disabled='true'>
              <Form.Control disabled className='input-outline' style={{ width: '25rem' }} type='email' placeholder='Tu Correo Electronico'></Form.Control>
              <Button disabled className='px-5'>Cotizar</Button>
            </Form.Group>
            <p style={smallTextStyle}>Al suscribirte, aceptas nuestra Política de Privacidad y das tu <br /> consentimiento para recibir actualizaciones.</p>
          </Form>
        </div>
      </div>
      <div className='d-flex w-100 mb-lg-4 px-lg-5'>
        <div className='w-100 text-center d-flex flex-column px-lg-5'>
          <hr className='align-self-center' />
          <p style={textStyle}>© 2024 Carros a Centro America. Todos los Derechos Reservados.</p></div>
      </div>

    </section>
  );
};

export default Footer;
